//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "users/getField",
  mutationType: "users/updateField",
});

import { required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "ResetPasswordPage",
  data() {
    return {
      waiting: false,
      isPwd: false,
      isPwdC: false,
      errorMessage: null,
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  computed: {
    ...mapFields({
      password: "resetPassword.password",
      passwordConfirmation: "resetPassword.passwordConfirmation",
    }),
    passwordErrorMessage() {
      if (!this.$v.password.required) {
        return "Senha é obrigatória";
      }
      if (!this.$v.password.minLength) {
        return "Mínimo de 8 dígitos";
      }
      return "Preenchimento inválido"
    },
    passwordConfirmationErrorMessage() {
      if (!this.$v.passwordConfirmation.required) {
        return "Confirmação de senha é obrigatória";
      }
      if (!this.$v.passwordConfirmation.sameAsPassword) {
        return "Senha não confere";
      }
      return "Preenchimento inválido"
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        if (this.waiting) {
          this.$q.notify({
            message: "Por favor, aguarde.",
            position: 'top-right',
          });
          return;
        }
        this.waiting = true;
        //console.log("recover Token: ", this.$route.params.recoverToken);
        this.$store
          .dispatch("users/resetPassword", {
            token: this.$route.params.recoverToken,
          })
          .then((response) => {
            this.password = '';
            this.passwordConfirmation= '';
            this.waiting = false;
            this.$v.$reset();
            this.$router.push({ name: "SignIn" });
          })
          .catch((error) => {
            console.error(error);
            this.waiting = false;
          });
      }
    },
  },
};
